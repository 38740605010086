.selection-content {
    background-color: #eef2f6;
    .content-top {
        display: flex;
        justify-content: center;
        background-color: #fff;
        padding-bottom: 25px;
    }
    .select-center,
    .empty-center {
        width: 100%;
        height: calc(100vh - 140px);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-y: hidden;
        .MuiPaper-root {
            max-width: 500px;
            width: 100%;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1);
            .select-center__card-content {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #e3e6eb;
                .icon {
                    margin-right: 18px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 45px;
                        height: 45px;
                    }
                }

                &__title {
                    flex-direction: unset;
                }
            }
        }
    }
}
