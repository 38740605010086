.staff-planning {
    padding-top: 10px;
    .staff-planning-list {
        background-color: white;
        border-radius: 8px 8px 0 0;
        padding: 20px 0px;
        .MuiButtonBase-root {
            font-size: 16px;
            color: #666666;
        }
        .Mui-selected {
            color: #3f51b5;
        }
        .MuiTabs-indicator {
            background-color: #3f51b5;
        }
    }
    .staff-planning-panel {
        padding: 0;
        .MuiPaper-root {
            border-radius: 0 0 8px 8px;
        }
    }
}