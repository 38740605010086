.ratio-table__body-row {
    &:last-child {
        // background-color: #000;
        .ratio-table__body-row__title {
            border-bottom-left-radius: 8px;
        }
        .ratio-table__body-row__with-1-AT {
            &:last-child {
                border-bottom-right-radius: 8px;
            }
        }
    }
    .muitablecell-root {
        color: #555 !important;
        border-right: 1px solid #e3e6eb;
        border-bottom: 1px solid #e3e6eb;
    }
    .ratio-table__body-row__title {
        border: none !important;
        border-right: 1px solid #e3e6eb !important;
        border-left: 1px solid #e3e6eb !important;
        border-bottom: 1px solid #e3e6eb !important;
    }
    .ratio-table__body-row__trained-T {
        border: none !important;
        border-right: none !important;
        border-left: none !important;
        border-bottom: 1px solid #e3e6eb !important;
    }
    .ratio-table__body-row__with-1-AT {
        border: none !important;
        border-right: 1px solid #e3e6eb !important;
        border-left: none !important;
        border-bottom: 1px solid #e3e6eb !important;
    }
}
