.sizing-wrapper {
    height: auto;
    background-color: white;
    border-radius: 0 0 8px 8px;
    .sizing-top-filter {
        padding: 0 24px;
    }
    .group-filter {
        width: 100%;
        .input-number {
            width: 100%;
            height: 40px;
            .MuiInputBase-root {
                width: 100%;
                height: 100%;
            }
        }
        .date-filter {
            .date-picker {
                width: 160px;
            }
        }
    }
    .sizing-item-wrapper {
        margin-bottom: 20px;
        .sizing-title {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            .title-item-date {
                margin-right: 10px;
                margin-bottom: 0;
                color: #3f51b5;
            }
            .btn-edit-view {
                margin-right: 8px;
                color: #3f51b5;
                border: 1px solid #3f51b5;
                text-transform: none;
            }
        }
    }
}

.table-sizing-overall-container {
    border-radius: 8px;
    .table-sizing-overall {
        .table-sizing-overall-head {
            background-color: #303972;
            .MuiTableCell-root {
                font-size: 14px;
                font-weight: 600;
                color: white;
                border: 1px solid #e3e6eb;
            }
        }
        .table-sizing-overall-head-edit {
            background-color: #303972;
            .MuiTableCell-root {
                font-size: 14px;
                font-weight: 500;
                color: white;
                border: 1px solid #e3e6eb;
            }
        }
        .table-sizing-overall-body {
            background-color: #fff;
            .MuiTableCell-root {
                border: 1px solid #e3e6eb;
                .input-headcount {
                    .MuiInputBase-input {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.table-sizing-class-breakdown-page-container {
    border-top: 1px solid #e3e6eb;
    border-radius: 0;
    .table-sizing-class-breakdown-page {
        .table-sizing-class-breakdown-page-head {
            .MuiTableCell-root {
                font-size: 14px;
                font-weight: 600;
                color: #222;
            }
        }

        // .table-sizing-class-breakdown-page-head-edit {
        //     background-color: #303972;
        //     .MuiTableCell-root {
        //         font-size: 14px;
        //         font-weight: 500;
        //         color: #222;
        //         border: 1px solid #e3e6eb;
        //     }
        // }
        .table-sizing-class-breakdown-page-body {
            background-color: #fff;
            .MuiTableCell-root {
                // border: 1px solid #e3e6eb;
                .input-headcount {
                    .MuiInputBase-input {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.sizing-wrapper-export {
    padding: 0;
    .export-sizing {
        width: 100%;
        overflow: hidden;
        padding: 20px;
        .group-filter {
            .input-number {
                width: 100%;
                height: 40px;
                .MuiInputBase-root {
                    width: 100%;
                    height: 100%;
                }
            }
            .date-filter {
                .date-picker {
                    margin-left: 10px;
                }
            }
        }
    }
}
