.container {
    .hrbt {
        border-bottom: 1px solid #000;
    }
}

.css-xikmq4-MuiDivider-root {
   display: none;
}

.chip-relief {
    border: 1px solid #EAAD1C;
    background: #FCC43E;
    color: #303972;
    margin-left: 8px;
    border-radius: 4px;
    font-weight: 600;
}
