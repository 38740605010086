.active {
    background: #E8EAF6;
    color: #3F51B5;
    cursor: pointer;
    border-radius: 8px;
}

@mixin active-css {
    background: #E8EAF6!important;
    color: #3F51B5!important;
    span{
        color: #3F51B5!important;
    }
    path{
        stroke: #3F51B5!important;
    }
  }

.item:hover {
    @include active-css;
}

.activeItem{
    @include active-css;
}

.arrowDown {
    transform: rotate(90deg);
}

