.diplomas-select {
    position: relative;
    input.MuiSelect-nativeInput {
        position: absolute;
    }
}

.group-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}