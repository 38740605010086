.box-res-ratio {
    height: 60%;
    width: 80%;
    text-align: center;
    border-radius: 8px;
    color: wheat;
    cursor: help;
    span {
        width: 100%;
        margin-top: 7px;
    }
    .number-tag {
        p {
            margin-bottom: 0px;
        }
        span.MuiBadge-badge {
            right: 6px;
            top: -13px;
            padding: 0 4px;
            width: 30px;
        }
    }
}

.success {
    background-color: #4caf50;
}

.over {
    background-color: #4cbc9a;
}

.warn {
    background-color: #ff9800;
}

.error {
    background-color: #f44336;
}

.button-select {
    width: 100%;
    height: 100% !important;
    min-width: 0px !important;
    padding: 2px 0px;
    border-radius: 20px;
    background-color: #3f51b5;
    font-size: 13px !important;
}

.box-detail-class {
    display: flex;
    align-items: center;
    background-color: #bbdefb;
    border: 1px solid #90caf9;
    padding: 4px 16px 4px;
    border-radius: 8px;
}

.table-container.MuiTableContainer-root {
    .css-16heys5-MuiTableCell-root {
        padding: 8px 16px;
    }
    .css-102lq7w-MuiTypography-root {
        font-size: 12px;
    }
    &::-webkit-scrollbar {
        height: 8px;
        background-color: #f5f5f5;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }
}

.cel-shadow {
    width: 100%;
    min-width: 300px;
    height: 56px;
    display: flex;
    align-items: center;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    .group-name {
        width: 70%;
        .group-name-icon {
            display: flex;
            align-items: center;
        }
        .teacher {
            color: #4caf50;
        }
        .assistant {
            color: #ff9800;
        }
    }
    .group-actions {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 30%;
        position: relative;
        .group-item {
            width: 40px;
            display: flex;
            justify-content: center;
            button:hover {
                background-color: transparent;
            }
        }
    }
    .title {
        padding: 12px;
        margin-bottom: 0;
        white-space: nowrap;
    }
    .font-weight-bold {
        font-weight: 600;
    }
}

.cell-time {
    width: 100%;
    max-width: 1200px;
    height: 56px;
    display: flex;
    align-items: center;
    .cell-item {
        width: calc(100% / 25);
        max-width: 150px;
        min-width: 80px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
        .time {
            width: 90%;
        }
    }
}

// css dropdown select staff in staff deployment
.dropdown-select-staff {
    // position: absolute;
    top: 120px;
    left: 3px;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 8px 1px;
    height: auto;
    .MuiPaper-root {
        height: 100%;
        min-height: 240px;
        min-width: 360px;
        padding-bottom: 27px;
        &::-webkit-scrollbar {
            width: 0.1px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 1px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f5f5f5;
        }
    }
    .MuiTableContainer-root {
        .css-16heys5-MuiTableCell-root {
            padding: 8px 16px;
        }
        .css-102lq7w-MuiTypography-root {
            font-size: 12px;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background-color: #f5f5f5;
        }
    }
    .bottom-actions {
        padding: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        background: white;
        border-top: 1px solid #d9d9d9;
        border-radius: 0 0 8px 8px;
        button {
            font-size: 12px;
            padding: 3px;
        }
    }
    .empty-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
            width: 145px;
        }
    }
}

.select-check-staff {
    .Mui-checked {
        color: #3f51b5;
    }
}
// end

.border-0 {
    border: 0;
}

// css replication
.replication-modal {
    .replication-content {
        .MuiGrid-root {
            width: 100%;
            .MuiPaper-root {
                width: 100%;
                height: 100%;
                max-height: 315px;
                overflow: auto;
                .MuiTableContainer-root {
                    width: 100%;
                }
            }
        }
    }
    .btn-save {
        background-color: #3f51b5;
        .MuiLoadingButton-loadingIndicator {
            left: 10px;
            margin-right: 10px;
        }
    }
}

.warning-conflict-modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiPaper-root {
        width: 100%;
        border-radius: 0;
        border: 1px solid transparent;
        .card-content {
            display: flex;
            border-bottom: 1px solid #e3e6eb;
            .icon {
                margin-right: 18px;
                display: flex;
                align-items: center;
                img {
                    width: 45px;
                    height: 45px;
                }
            }
        }
        .content {
            border-bottom: 1px solid #e3e6eb;
        }
    }
}
