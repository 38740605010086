.group-filter {
    display: flex;
    margin-bottom: 20px;
    .date-picker {
        .MuiOutlinedInput-input {
            padding: 10px 14px;
            font-size: 14px;
        }
    }
}

button:disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    margin-left: 20px;
    border-radius: 5px;
    background-color: #6d6d6d;
    color: #6d6d6d;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-flashing::before {
    left: -15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #6d6d6d;
    color: #6d6d6d;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}
.dot-flashing::after {
    left: 15px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #6d6d6d;
    color: #6d6d6d;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dot-flashing {
    0% {
        background-color: #6d6d6d;
    }
    50%,
    100% {
        background-color: rgba(152, 128, 255, 0.2);
    }
}
