.wrapper-menu {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
}

.menu {
    position: relative;
    width: 100%;
}

.active {
    .group-menu {
        background: #e8eaf6;
        color: #3f51b5;
        span {
            path {
                stroke: #3f51b5 !important;
            }
        }
    }
}

.group-menu {
    height: 40px;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    padding: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    &:hover {
        background: #e8eaf6;
        color: #3f51b5;
        span {
            path {
                stroke: #3f51b5 !important;
            }
        }
    }
}

.dropdown-menu {
    min-width: 235px;
    background: #fff;
    transition: all linear 0.3s;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    position: absolute;
    &::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 20px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid white;
        background: transparent;
    }
    ul {
        list-style: none;
        padding: 0px;
        margin: 8px 0px;
        border-radius: 8px;
        &:hover {
            svg {
                color: #616161;
            }
        }
    }
}

a {
    text-decoration: none;
    color: #000;
}
